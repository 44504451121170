<template>
    <div class="container grid">
      <Toolbar class="lg:col-6 md:col-12">
        <template #start style="width:80%">
          <div class="actions">
            <Button v-if="devices.length > 1 && enableCamera" icon="pi pi-sync" @click="onSwitchCamera" class="mr-1"/>
            <ToggleButton v-model="enableCamera" onIcon="pi pi-pencil p-button-primary" offIcon="pi pi-camera"/>
          </div>
          <div class="qrcode" >
            <span style="font-size: 1.25rem;">{{ resource[1] }}</span>
            <div class="scannedTime">{{scannedLabel}}</div>
          </div>
        </template>

        <template #end>
            <Button v-if="enableCamera" icon="pi pi-camera" @click="onCapture"/>
            <Button v-if="!enableCamera" icon="pi pi-save" @click="onSaveNote"/>
        </template>
      </Toolbar>
      <div class="col-12">{{message}}</div>
      <div class="lg:col-6 md:col-12">
        <camera-stream ref="camera"  v-if="enableCamera" :camera="camera" @cameras="onCameras" @decode="onDecode"></camera-stream>
        <div class="card p-fluid grid" v-if="!enableCamera">
          <span class="col-12 p-float-label">
            <Textarea id="note" type="text" v-model="note" rows="5"/>
            <label for="note">{{$t('camera.note')}}</label>
          </span>
          <div class="field-checkbox mb-0">
            <Checkbox id="abnormal" v-model="abnormal" :binary="true" />
            <label for="abnormal">{{$t('camera.abnormal')}}</label>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CameraStream from '@/components/camera/CameraStream';
import {fromBase64, extractResource} from '@/utils/common'
import ImageService from '@/service/ImageService'
import {Service} from '@/service/common'
import authHeader from '@/service/auth.header'
import formatter from '@/utils/formatter';
export default {
    name: "UserCamera",
    components: {CameraStream},
    data() {
        return {
            message: null,
            camera: "auto",
            note: null,
            abnormal: false,
            enableCamera: true,
            deviceId: null,
            devices: [],
            resource: [],
            scannedTime: null,
        };
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        scannedLabel() {
          return formatter.formatTime(this.scannedTime);
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            if (this.devices.length > 0) {
                const first = this.devices[0];
                this.deviceId = first.deviceId;
            }
        }
    },
    methods: {
        onSwitchCamera() {
          if(this.devices.length > 1) {
            if (this.camera == 'auto' || this.camera == 'rear') {
              this.camera = 'front';
            } else if (this.camera == 'front') {
              this.camera = 'rear';
            }
          }
        },
        onCapture() {
            this.message = null;
            if (Array.isArray(this.resource) && this.resource[0] && this.resource[1]) {
              const camera = this.$refs.camera;
              const canvas = camera.getCanvas();
              const ctx = canvas.getContext("2d");
              ctx.font = "30px Georgia";
              ctx.fillStyle = "#FF0000";
              ctx.fillText(this.resource[1], 10, 40);
              canvas.toBlob((blob) => {
                ImageService.uploadImage(blob, this.resource[0], this.resource[1]).then((res) => {
                  this.message = this.$t('image.upload_success',[res.data]);
                  camera.clearCanvas(canvas);
                }).catch(() => {
                  this.message = this.$t('image.upload_error');
                });
              })
            } else {
              this.message = this.$t('error.package_missing')
            }
        },
        onSaveNote() {
          if(this.resource[0] && this.resource[1]) {
            const data = {
              resourceType: this.resource[0],
              resourceCode: this.resource[1],
              abnormal: this.abnormal,
              message: this.note
            }
            this.worklogService.create(data).then(res => console.log(res))
          }
        },
        onDecode(decodedString) {
          try {
            const resource = extractResource(decodedString);
            this.resource = resource;
            this.scannedTime = new Date()
          } catch(e) {
            this.scannedTime = null;
            console.log(e)
          }
        },
        /**
         * load available cameras
         */
        loadCameras() {
          try {
            const mediaDevices = navigator.mediaDevices;
            if(mediaDevices) {
                mediaDevices.enumerateDevices()
                .then(deviceInfos => {
                  alert(deviceInfos.length);
                  for (let i = 0; i !== deviceInfos.length; ++i) {
                    let deviceInfo = deviceInfos[i];
                    alert(deviceInfo.kind + ':' + deviceInfo.label);
                  }
                  this.devices = deviceInfos.filter(({kind}) => kind === 'videoinput')
                  console.log(this.devices);
                  })
                .catch(error => {
                  alert(error)
                  this.$emit("notsupported", error)
                });
              }
          } catch (err) {
            console.log(err);
          }
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        beforeSendFile(event) {
          const headers = authHeader();
          event.xhr.setRequestHeader('Authorization',headers.Authorization);
          console.log(event.formData);
          console.log(event.xhr);
        }
    },
    mounted() {
      this.worklogService = new Service('worklog');
      if(this.$route.params.resource) {
        this.resource = fromBase64(this.$route.params.resource).split('|');
      }
    },
    beforeUnmount() {
      this.$refs.camera.stopCamera();
    }
};
</script>
<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
  }
  .container .p-toolbar {
    padding: 0 1rem;
    margin: -0.5rem 0;
    border: 0;
    font-weight: 600;
  }
  .container .qrcode {
    margin-left: 15%
  }
  .qrcode .scannedTime {
    font-size: 1rem;
    font-weight: normal;
    font-style:italic;
  }
  .actions {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
</style>
